var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" },
                          on: {
                            click: function($event) {
                              _vm.active = !_vm.active
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Liste Des Rôles ")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        visible: _vm.active,
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c("div", { staticClass: "mb-0 shadow" }, [
                          _c(
                            "div",
                            { staticClass: "table-responsive mb-0" },
                            [
                              _c("dataset", {
                                attrs: { "ds-data": _vm.equipments },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var ds = ref.ds
                                      return [
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", {
                                            staticClass: "col-md-6"
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "col-md-6" },
                                            [
                                              _c("dataset-search", {
                                                attrs: {
                                                  "ds-search-placeholder":
                                                    "Search..."
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-12" },
                                            [
                                              _c("div", {}, [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "table table-hover d-md-table"
                                                  },
                                                  [
                                                    _c("thead", [
                                                      _c(
                                                        "tr",
                                                        _vm._l(
                                                          _vm.cols,
                                                          function(th) {
                                                            return _c(
                                                              "th",
                                                              { key: th.field },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      th.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    ]),
                                                    _c("dataset-item", {
                                                      attrs: { tag: "tbody" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              var rowIndex =
                                                                ref.rowIndex
                                                              return [
                                                                _c(
                                                                  "tr",
                                                                  {
                                                                    class: {
                                                                      "bg-soft-info":
                                                                        _vm.selectedIndex ==
                                                                        rowIndex
                                                                    },
                                                                    staticStyle: {
                                                                      cursor:
                                                                        "pointer"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.selectRow(
                                                                          row,
                                                                          rowIndex
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.code
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.name
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.brand
                                                                            ? row
                                                                                .brand
                                                                                .designation
                                                                            : "N/A"
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.category
                                                                            ? row
                                                                                .category
                                                                                .designation
                                                                            : "N/A"
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.type
                                                                            ? row
                                                                                .type
                                                                                .designation
                                                                            : "N/A"
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ])
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-md-row flex-column justify-content-between align-items-center"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-3 mb-2 mb-md-0"
                                              },
                                              [
                                                _c("dataset-show", {
                                                  attrs: {
                                                    "ds-show-entries": 5
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c("dataset-pager")
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("SideButtons", {
            attrs: {
              reset: _vm.resetForm,
              canEdit: _vm.canEdit,
              select: _vm.have
            },
            on: {
              add: function($event) {
                _vm.add = $event
              },
              edit: function($event) {
                _vm.edit = $event
              },
              resetEmit: function($event) {
                _vm.resetForm = $event
              },
              delete: function($event) {
                _vm.destroy = $event
              }
            }
          }),
          _c("div", { staticClass: "col-lg-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("fieldset", { attrs: { disabled: _vm.disabled } }, [
                  _c(
                    "form",
                    {
                      staticClass: "needs-validation",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.formSubmit($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 col-lg" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "code" } }, [
                                _vm._v("Code ")
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.equipForm.code,
                                    expression: "equipForm.code"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "code",
                                  type: "text",
                                  placeholder: "",
                                  value: ""
                                },
                                domProps: { value: _vm.equipForm.code },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.equipForm,
                                      "code",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 col-lg" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "deptName" } }, [
                                _vm._v("Désignation *")
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.equipForm.name,
                                    expression: "equipForm.name"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.submitted &&
                                    _vm.$v.equipForm.name.$error
                                },
                                attrs: {
                                  id: "deptName",
                                  type: "text",
                                  placeholder: "",
                                  value: ""
                                },
                                domProps: { value: _vm.equipForm.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.equipForm,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm.submitted && _vm.$v.equipForm.name.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      !_vm.$v.equipForm.name.required
                                        ? _c("span", [
                                            _vm._v("Le nom est obligatoire.")
                                          ])
                                        : _vm._e(),
                                      !_vm.$v.equipForm.name.maxLength
                                        ? _c("span", [
                                            _vm._v(
                                              "La taille du champs est limitée a 64 charactères."
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 col-lg" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "deptName" } }, [
                                  _vm._v("Catégorie *")
                                ]),
                                _c("multiselect", {
                                  class: {
                                    disabledMS: _vm.disabled,
                                    "is-invalid":
                                      _vm.submitted &&
                                      _vm.$v.equipForm.category.$error
                                  },
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    label: "designation",
                                    options: _vm.categories,
                                    placeholder: "Select category",
                                    "allow-empty": false
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "singleCateg",
                                      fn: function(ref) {
                                        var categ = ref.categ
                                        return [
                                          _vm._v(_vm._s(categ.designation))
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.equipForm.category,
                                    callback: function($$v) {
                                      _vm.$set(_vm.equipForm, "category", $$v)
                                    },
                                    expression: "equipForm.category"
                                  }
                                }),
                                _vm.submitted &&
                                _vm.$v.equipForm.category.$error
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        !_vm.$v.equipForm.category.required
                                          ? _c("span", [
                                              _vm._v("Catégorie obligatoire.")
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 col-lg" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "deptName" } }, [
                                  _vm._v("Type *")
                                ]),
                                _c("multiselect", {
                                  class: {
                                    disabledMS: _vm.disabled,
                                    "is-invalid":
                                      _vm.submitted &&
                                      _vm.$v.equipForm.type.$error
                                  },
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    label: "designation",
                                    options: _vm.types,
                                    placeholder: "Select type",
                                    "allow-empty": false
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "singleType",
                                      fn: function(ref) {
                                        var type = ref.type
                                        return [
                                          _vm._v(_vm._s(type.designation))
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.equipForm.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.equipForm, "type", $$v)
                                    },
                                    expression: "equipForm.type"
                                  }
                                }),
                                _vm.submitted && _vm.$v.equipForm.type.$error
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        !_vm.$v.equipForm.type.required
                                          ? _c("span", [
                                              _vm._v("Type obligatoire.")
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 col-lg" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "deptName" } }, [
                                  _vm._v("Marque *")
                                ]),
                                _c("multiselect", {
                                  class: {
                                    disabledMS: _vm.disabled,
                                    "is-invalid":
                                      _vm.submitted &&
                                      _vm.$v.equipForm.brand.$error
                                  },
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    label: "designation",
                                    options: _vm.brands,
                                    placeholder: "Select brand",
                                    "allow-empty": false
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "singleBrand",
                                      fn: function(ref) {
                                        var brand = ref.brand
                                        return [
                                          _vm._v(_vm._s(brand.designation))
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.equipForm.brand,
                                    callback: function($$v) {
                                      _vm.$set(_vm.equipForm, "brand", $$v)
                                    },
                                    expression: "equipForm.brand"
                                  }
                                }),
                                _vm.submitted && _vm.$v.equipForm.brand.$error
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        !_vm.$v.equipForm.brand.required
                                          ? _c("span", [
                                              _vm._v("Marque obligatoire.")
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "validationCustom02" } },
                              [_vm._v("Commentaires")]
                            ),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.equipForm.comments,
                                  expression: "equipForm.comments"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { id: "", cols: "30", rows: "2" },
                              domProps: { value: _vm.equipForm.comments },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.equipForm,
                                    "comments",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        !_vm.disabled
                          ? _c(
                              "div",
                              { staticClass: "col text-right mr-2 mb-3" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-label mr-2",
                                    attrs: {
                                      disabled: _vm.$v.equipForm.$invalid,
                                      variant: "primary"
                                    },
                                    on: { click: _vm.formSubmit }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "far fa-save text-white label-icon"
                                    }),
                                    _vm._v(" Enregitrer ")
                                  ]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-label",
                                    attrs: { variant: "light" },
                                    on: { click: _vm.cancelBtn }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-times-circle text-danger label-icon"
                                    }),
                                    _vm._v(" Annuler ")
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }